export const FILE_UPLOAD = "FILE_UPLOAD";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const RETRIEVE_PROJECT = "RETRIEVE_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";


export const CREATE_SERIES = "CREATE_SERIES";
export const RETRIEVE_SERIES = "RETRIEVE_SERIES";
export const UPDATE_SERIES = "UPDATE_SERIES";
export const DELETE_SERIES = "DELETE_SERIES";


export const CREATE_EPISODES = "CREATE_EPISODES";
export const RETRIEVE_EPISODES = "RETRIEVE_EPISODES";
export const UPDATE_EPISODES = "UPDATE_EPISODES";
export const DELETE_EPISODES = "DELETE_EPISODES";


export const CREATE_VIDEOS = "CREATE_VIDEOS";
export const RETRIEVE_VIDEOS = "RETRIEVE_VIDEOS";
export const UPDATE_VIDEOS = "UPDATE_VIDEOS";
export const DELETE_VIDEOS = "DELETE_VIDEOS";
