// export const FILE_UPLOAD = "FILE_UPLOAD";
export const CREATE_DISTRICTS = "CREATE_DISTRICTS";
export const RETRIEVE_DISTRICTS = "RETRIEVE_DISTRICTS";
export const UPDATE_DISTRICTS = "UPDATE_DISTRICTS";
export const DELETE_DISTRICTS = "DELETE_DISTRICTS";


export const CREATE_GENRES = "CREATE_GENRES";
export const RETRIEVE_GENRES = "RETRIEVE_GENRES";
export const UPDATE_GENRES = "UPDATE_GENRES";
export const DELETE_GENRES = "DELETE_GENRES";
