export const FILE_UPLOAD = "FILE_UPLOAD";
export const CREATE_BASIC_SETTINGS = "CREATE_BASIC_SETTINGS";
export const RETRIEVE_BASIC_SETTINGS = "RETRIEVE_BASIC_SETTINGS";
export const UPDATE_BASIC_SETTINGS = "UPDATE_BASIC_SETTINGS";
export const DELETE_BASIC_SETTINGS = "DELETE_BASIC_SETTINGS";


export const CREATE_SERIES = "CREATE_SERIES";
export const RETRIEVE_SERIES = "RETRIEVE_SERIES";
export const UPDATE_SERIES = "UPDATE_SERIES";
export const DELETE_SERIES = "DELETE_SERIES";


export const CREATE_EPISODES = "CREATE_EPISODES";
export const RETRIEVE_EPISODES = "RETRIEVE_EPISODES";
export const UPDATE_EPISODES = "UPDATE_EPISODES";
export const DELETE_EPISODES = "DELETE_EPISODES";


export const CREATE_VIDEOS = "CREATE_VIDEOS";
export const RETRIEVE_VIDEOS = "RETRIEVE_VIDEOS";
export const UPDATE_VIDEOS = "UPDATE_VIDEOS";
export const DELETE_VIDEOS = "DELETE_VIDEOS";



export const CREATE_PAYMENT_CREDENTIAL_SETUP = "CREATE_PAYMENT_CREDENTIAL_SETUP";
export const RETRIEVE_PAYMENT_CREDENTIAL_SETUP = "RETRIEVE_PAYMENT_CREDENTIAL_SETUP";
export const UPDATE_PAYMENT_CREDENTIAL_SETUP = "UPDATE_PAYMENT_CREDENTIAL_SETUP";
export const DELETE_PAYMENT_CREDENTIAL_SETUP = "DELETE_PAYMENT_CREDENTIAL_SETUP";
