// export const FILE_UPLOAD = "FILE_UPLOAD";
export const CREATE_CONTENTS = "CREATE_CONTENTS";
export const RETRIEVE_CONTENTS = "RETRIEVE_CONTENTS";
export const UPDATE_CONTENTS = "UPDATE_CONTENTS";
export const DELETE_CONTENTS = "DELETE_CONTENTS";


export const CREATE_INDIE_MOVIE = "CREATE_INDIE_MOVIE";
export const RETRIEVE_INDIE_MOVIE = "RETRIEVE_INDIE_MOVIE";
export const UPDATE_INDIE_MOVIE = "UPDATE_INDIE_MOVIE";
export const DELETE_INDIE_MOVIE = "DELETE_INDIE_MOVIE";


export const CREATE_UPCOMING = "CREATE_UPCOMING";
export const RETRIEVE_UPCOMING = "RETRIEVE_UPCOMING";
export const UPDATE_UPCOMING = "UPDATE_UPCOMING";
export const DELETE_UPCOMING = "DELETE_UPCOMING";


export const CREATE_TRENDING = "CREATE_TRENDING";
export const RETRIEVE_TRENDING = "RETRIEVE_TRENDING";
export const UPDATE_TRENDING = "UPDATE_TRENDING";
export const DELETE_TRENDING = "DELETE_TRENDING";
